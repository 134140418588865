import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-norecords',
  templateUrl: './norecords.component.html',
  styleUrls: ['./norecords.component.scss']
})
export class NorecordsComponent implements OnInit {
  @Input() title: string;
  constructor() { }

  ngOnInit(): void {
  }

}
