import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable({ providedIn: 'root' })
export class LanguageService {
  public languages: string[] = ['en', 'es', 'de', 'it', 'ru','ar'];

  constructor(public translate: TranslateService, private cookieService: CookieService) {
    let browserLang;
    this.translate.addLangs(this.languages);
    if (this.cookieService.check('lang')) {
      browserLang = this.cookieService.get('lang');
      if (browserLang == 'ar') {
          this.translate.use("ar");
          document.getElementsByTagName("body")[0].setAttribute("dir", "rtl");
      }
    }
    else {
      browserLang = translate.getBrowserLang();
    }
    translate.use(browserLang.match(/en|es|de|it|ru|er|ar/) ? browserLang : 'en');
  }

  public setLanguage(lang) {
    this.translate.use(lang);
    this.cookieService.set('lang', lang);
    if (lang == 'ar') {
      document.getElementsByTagName("body")[0].setAttribute("dir", "rtl");
    }
    else {
      document.getElementsByTagName("body")[0].setAttribute("dir", "ltr");
    }
  }

}
