import { Component, OnInit } from '@angular/core';
import { LoaderService } from '../../../core/services/loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {

  loading: boolean;
  isLogin:any;
  constructor(private loaderService: LoaderService) {
    const v = this.loaderService.isLoading.subscribe((v) => {
      this.loading = v;
      if(this.loading) {
        this.clearLoading();
      }
    });
  }

  ngOnInit(): void {
    this.isLogin = false;
    if (sessionStorage.getItem('user')) {
      this.isLogin = true;
    }
    
  }

  clearLoading() {
    setTimeout(() => {
      if( this.loading) {
        this.loading = false;
      }
    }, 30000);
  }
}
