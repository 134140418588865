const translateNavigation = {
  "Dashboard": "DASHBOARD",
  "All Attributes": "ALL_ATTRIBUTE",
  "Attribute Group": "ATTRIBUTE_GROUP",
  "Attribute Family": "ATTRIBUTE_FAMILY",
  "All Orders": "ALL_ORDERS",
  "Product List": "PRODUCT_LIST",
  "Brand": "BRAND",
  "Category": "CATEGORY",
  "Sliders": "SLIDERS",
  "Voucher Management": "VOUCHER_MANAGEMENT",
  "Content Management": "CONTENENT_MANAGEMENT",
  "Vendor Store": "VENDOR_STORE",
  "News Letter Subscriptions": "NEWS_LETTER",
  "Membership": "MEMBERSHIP",
  "Super Admin": "SUPERADMIN",
  "Admin": "ADMIN",
  "Vendors": "VENDORS",
  "Customers": "CUSTOMERS"
};

const Navigation = [
  "Dashboard",
  "All Attributes",
  "Attribute Group",
  "Attribute Family",
  "All Orders",
  "Product List",
  "Brand",
  "Category",
  "Sliders",
  "Voucher Management",
  "Content Management",
  "Vendor Store",
  "News Letter Subscriptions",
  "Membership",
  "Super Admin",
  "Admin",
  "Vendors",
  "Customers"
];

const routeBuildLink = {
  "Dashboard": "/dashboard",
  "All Attributes": "/attributes/view",
  "Attribute Group": "/attributes/group",
  "Attribute Family": "/attributes/family",
  "All Orders": "/orders/view-orders",
  "Product List": "/product",
  "Brand": "/brand",
  "Category": "/category",
  "Sliders": "/sliders",
  "Voucher Management": "/voucher-management",
  "Content Management": "/content-management",
  "Vendor Store": "/vendor-store",
  "News Letter Subscriptions": "/newsletter-subscriptions",
  "Membership": "/membership",
  "Super Admin": "/users/super-admin",
  "Admin": "/users/admin",
  "Vendors": "/users/vendor",
  "Customers": "/users/customers"
}

export { translateNavigation, Navigation, routeBuildLink };
