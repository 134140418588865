import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Request } from "../../core/helpers/request-handler";
import { User } from "../models/auth.models";

@Injectable({ providedIn: "root" })
export class AuthfakeauthenticationService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(private http: HttpClient, public requestHandler: Request) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(sessionStorage.getItem("currentUser"))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  login(email: string, password: string) {
    return this.http
      .post<any>(this.requestHandler.baseurl + "/login", { email, password })
      .pipe(
        map((user) => {
          // login successful if there's a jwt token in the response
          if (user && user.data && user.data.token) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            sessionStorage.setItem("currentUser", JSON.stringify(user.data.token));
            sessionStorage.setItem("user", user.data.token);
            sessionStorage.setItem("user_id", user.data.result._id);
            this.currentUserSubject.next(user.data.token);
          }
          return user;
        })
      );
  }

  otpVerify(data: any) {
    return this.http
      .put<any>(this.requestHandler.baseurl + "/user/auth/otp-validation", data)
      .pipe(
        map((user) => {
          // login successful if there's a jwt token in the response
          if (user && user.data && user.data.token) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            sessionStorage.setItem("currentUser", JSON.stringify(user.data.token));
            sessionStorage.setItem("user", user.data.token);
            sessionStorage.setItem("user_id", user.data.result._id);
            this.currentUserSubject.next(user.data.token);
          }
          return user;
        })
      );
  }

  logout() {
     // remove user from local storage to log user out
     localStorage.removeItem("currentUser");
     sessionStorage.removeItem("currentUser");
     sessionStorage.removeItem("user");
     sessionStorage.removeItem("user_id");
     this.currentUserSubject.next(null);
  }
}
