import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
class Request {
  constructor(private http: HttpClient, public _cookiesService: CookieService) {
  }
  baseurl = "https://api.tamimi.co/api/v1";
  // baseurl = "http://localhost:8080/api/v1";
  httpOptions = {
    headers: new HttpHeaders({
      'Authorization': JSON.parse(sessionStorage.getItem("currentUser")),
      'Accept-Language':this._cookiesService.get('lang'),
    })
  }
  httpOptionsFormdata = {
    headers: new HttpHeaders({
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'multipart/form-data',
      'Authorization': JSON.parse(sessionStorage.getItem("currentUser"))
    })
  }
}
export { Request }
