import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthfakeauthenticationService } from '../services/authfake.service';
import { Router } from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private authFackservice: AuthfakeauthenticationService,
        private router: Router,
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(map(event => {
            if (event instanceof HttpResponse) {
                if(event && event.status == 401) {
                    this.authFackservice.logout();
                    this.router.navigate(['/account/login']);
                }
            }
            return event;
        }));
    }
}
